.banner-head {
    display: flex;
}

.banner {
    background-color: inherit;
    color: #333;
    /* padding: 20px; */
    display: flex;
    flex-direction: column;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    overflow: auto;
  }
  
  .banner-content {
    padding-bottom: 20px;
  }

  .banner-content h1 {
    margin-top: 0px;
  }

  .banner-content p {
    text-align: left;
  }

  .completed-homes h2 {
    color: #333;
    text-align: left;
    font-weight: bold;
  }

  h1 {
    font-size: 24px;
    color: #000;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  h2 {
    font-size: 20px;
    color: #000;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .completed-homes ul {
    /* list-style-type: none;
    padding: 0;
    display: flex; */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    /* padding-top: 70px; */
    /* text-align: center; */
    /* margin: 5px; */
  }
  
  .completed-homes li {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
  }

  .completed-homes a {
    color: black;
  }

  .completed-homes h2 {
    margin-top: 0px;
  }
  
  .completed-homes li img {
    /* width: 245px;
    height: 175px; */
    width: 217px;
    height: 155px;
    margin-right: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .completed-homes li span {
    font-size: 16px;
  }

  /* .banner-logo img {
     padding: 57px 0px;
  } */
  
  @media (max-width: 768px) {
    .banner {
      flex-direction: column;
    }

    .banner-logo {
        display: none;
    }
  
    .completed-homes li {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .completed-homes li img {
      margin-bottom: 5px;
    }
  }

  @media (max-width: 484px) {
    .banner-content h1 {
      font-size: 19px;
    }
  }