.head {
    display: flex;
    justify-content: center;
    align-items: center;
}

.see-more {
    display: flex;
    align-items: end;
}

.see-more a {
    color: black;
}