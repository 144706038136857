/* Common CSS for Forms */

body {
    font-family: 'Arial', sans-serif;
    background-color: #f8f8f8;
    margin: 0;
    padding: 0;
  }
  
  .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  label {
    margin: 10px;
  }
  
  input,
  select {
    padding: 8px;
    margin-bottom: 15px;
    width: 100%;
    box-sizing: border-box;
  }
  
  button {
    padding: 10px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  button[type="button"] {
    margin-right: 10px;
    background-color: #e0e0e0;
    color: #333;
  }
  
  button[type="button"]:hover {
    background-color: #ccc;
  }
  
  h2 {
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  li {
    margin-bottom: 10px;
  }  

  .form-align {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .two_in_row {
    display: flex;
  }

  .message-container {
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
  }

  .success-message {
    background-color: #4CAF50;
    color: #fff;
  }
  
  .error-message {
    background-color: #e74c3c;
    color: #fff;
  }

  .investor_data_form_div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .investor_data_div {
    margin: 0px 5vw;
  }