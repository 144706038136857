html, body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

.navbar {
  padding: 0px 5px;
}

.nav-items {
  margin-right: 2.5rem;
}

.about, .top {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  /* padding-top: 70px; */
  text-align: center;
  grid-gap: 50px;
}

.about_box {
  /* margin: 18px 55px; */
  overflow: auto;
}

.about_box h2, p {
  text-align: center;
}

.about_box img {
  height: 50%;
  width: 95%;
  border-radius: 50%;
}

h1 {
  margin-top: 40px;
}

.about_box h2 {
  text-align: center;
  margin: 1.5rem 0rem;
  font-weight: bold;
}

.about_box p {
  font-weight: lighter;
}


/* For Carousel */
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}

.carousel-control-next,
.carousel-control-prev {
    filter: invert(100%);
}

.carousel_caption {
  position: absolute;
  top: 0;
}

.carousel-indicators li {
  filter: invert(100%);
}

.carousel_caption h2 {
  color: #fff;
}

.carousel img {
  height: 300px;
  padding: 30px;
  margin: 40px;
  /* border-radius: 50%; */
  margin-left: auto;
  margin-right: auto;
}

.carousel a {
  margin: 0 auto;
}

.carousel a img {
  border: 5px solid #212529;
  padding: 0px;
}

.btn-outline-success {
  background-color: white;
  color:black;
  border-color:white;
}

footer {
  background-color: #212529;
  color: white;
  padding: 20px 0px;
  margin-top: 15vh;
}

@media (max-width: 991px) {
  .navbar-nav a {
    margin-left: 4rem;
  }
}