.styled-table {
    width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 16px;
    text-align: left;
  }
  
  .styled-table th, .styled-table td {
    padding: 12px 15px;
    border-bottom: 1px solid #ddd;
  }
  
  .styled-table th {
    background-color: #f2f2f2;
  }
  
  .styled-table tbody tr:hover {
    background-color: #f5f5f5;
  }  